<template>
    <div class="fill-height box">
        <v-card flat class="fill-height box">
            <v-app-bar flat class="row header mx-0">
                <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
                <v-divider class="mx-3" inset vertical></v-divider>
                <span class="headline">Rental Shop</span>

            </v-app-bar>

            <v-card-text class="row content mx-0">
                <v-row align-content="center" justify="center">
                    <v-col cols="10" md="8" lg="6">
                        <div v-show="measurements == null">
                            <div class="headline pl-0 mt-5">Enter your height and weight</div>
                            <v-card-subtitle class="pt-0 pl-0">We'll use this information to calculate your measurements</v-card-subtitle>

                            <v-form ref="formHeightWeight">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-select v-model="heightFeet" :items="feet" outlined placeholder="Height (feet)" :rules="[rules.required, rules.intOnly]"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select v-model="heightInches" :items="inches" outlined placeholder="Height (inches)" :rules="[rules.required, rules.intOnly, rules.inches]"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field v-model="weight" outlined placeholder="Weight" suffix="lbs" v-mask="'###'" :rules="[rules.required, rules.intOnly]"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" class="text-right">
                                        <v-btn depressed color="grey darken-4" class="white--text" :loading="calculating" @click="calculateMeasurements">Calculate</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>

                        <div v-show="measurements != null">
                            <v-card-title class="headline pl-0">Confirm your measurements</v-card-title>
                            <v-card-subtitle class="pl-0">The measurements below was calculated using your height and weight. <br v-if="$vuetify.breakpoint.mdAndUp">Please confirm the measurements or enter the correct ones.</v-card-subtitle>

                        
                            <v-form ref="form">
                                <v-row no-gutters class="mt-3">
                                    <!-- Coat -->
                                    <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                        <div class="text-center">
                                            <img :src="require('../assets/images/Coat.svg')" style="height:150px;" />
                                        </div>
                                        <div class="subtitle-1 text-center font-weight-light">
                                            <v-row>
                                                <v-col cols="3" offset="1" class="px-1">
                                                    <v-text-field v-model="coatSize" label="Size" :rules="[rules.required, rules.intOnly]" 
                                                        append-icon="mdi-video" @keydown="intOnly($event)" @click:append="openVideo('295824352')"></v-text-field>
                                                </v-col>
                                                <v-col cols="5" class="px-1">
                                                    <v-select v-model="coatLength" :items="coatLengthOptions" item-text="name" item-value="id"
                                                        label="Length" :rules="[rules.required]">

                                                        <template v-slot:item="{ item, on, attrs }">
                                                            <v-list-item v-on="on" v-bind="attrs">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        {{ getCoatLengthSubtitle( item.id ) }}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>

                                                    </v-select>
                                                </v-col>
                                                <v-col cols="2" md="3" class="px-1">
                                                    <v-text-field v-model="coatSleeve" label="Sleeve" :rules="[rules.required, rules.numeric]" 
                                                        @keydown="numbersOnly($event)"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>

                                    <!-- Pant -->
                                    <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                        <div class="text-center">
                                            <img :src="require('../assets/images/Pants.svg')" style="height:150px;" />
                                        </div>
                                        <div class="subtitle-1 text-center font-weight-light">
                                            <v-row>
                                                <v-col cols="1"></v-col>
                                                <v-col cols="3" class="px-1">
                                                    <v-text-field v-model="pantSize" label="Waist" :rules="[rules.required,rules.intOnly]" 
                                                        append-icon="mdi-video" @keydown="numbersOnly($event)" @click:append="openVideo('296455945')"></v-text-field>
                                                </v-col>
                                                <v-col cols="3" class="px-1">
                                                    <v-select v-model="pantType" :items="pantTypeOptions" label="Type" item-text="name" item-value="id" 
                                                        :rules="[rules.required]"></v-select>
                                                </v-col>
                                                <v-col cols="4" class="px-1">
                                                    <v-text-field v-model="pantLength" label="Outseam" :rules="[rules.required,rules.numeric]" 
                                                        append-icon="mdi-video" @keydown="numbersOnly($event)" @click:append="openVideo('295826571')"></v-text-field>
                                                </v-col>
                                                <v-col cols="1"></v-col>
                                            </v-row>
                                        </div>
                                    </v-col>

                                    <!-- Shirt -->
                                    <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                        <div class="text-center">
                                            <img :src="require('../assets/images/Shirt.svg')" style="height:150px;" />
                                        </div>
                                        <div class="subtitle-1 text-center font-weight-light">
                                            <v-row>
                                                <v-col cols="1"></v-col>
                                                <v-col cols="5">
                                                    <v-text-field v-model="shirtNeckSize" label="Neck" :rules="[rules.required,rules.numeric]" 
                                                        append-icon="mdi-video" @keydown="numbersOnly($event)" @click:append="openVideo('310569916')"></v-text-field>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-text-field v-model="shirtSleeveLength" label="Sleeve" :rules="[rules.required,rules.intOnly]" 
                                                        @keydown="numbersOnly($event)" append-icon="mdi-video" @click:append="openVideo('295827766')"></v-text-field>
                                                </v-col>
                                                <v-col cols="1"></v-col>
                                            </v-row>
                                        </div>
                                    </v-col>

                                    <!-- Shoes -->
                                    <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                                        <div class="text-center">
                                            <img :src="require('../assets/images/checkout-shoes.svg')" style="height:150px;" />
                                        </div>
                                        <div class="subtitle-1 text-center font-weight-light">
                                            <v-row>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="3" class="px-1">
                                                    <v-text-field v-model="shoeSize" label="Size" :rules="[rules.required,rules.numeric]" @keydown="numbersOnly($event)"></v-text-field>
                                                </v-col>
                                                <v-col cols="3" class="px-1">
                                                    <v-select v-model="shoeType" :items="shoeTypeOptions" label="Type" item-text="name" item-value="id" 
                                                        :rules="[rules.required]"></v-select>
                                                </v-col>
                                                <v-col cols="3"></v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </div>
                    </v-col>
                </v-row>

                <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
                    <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
                    <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
                </v-btn>

                <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep">
                    <v-icon left>mdi-arrow-right</v-icon> Next
                </v-btn>
            </v-card-text>
        </v-card>

        <v-dialog v-model="videoDialog" width="640" @click:outside="closeVideo">
            <div class="text-right white">
                <v-btn icon @click="closeVideo">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <iframe title="vimeo-player" :src="`https://player.vimeo.com/video/${videoSrc}?autoplay=1`" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { mapActions, mapGetters } from 'vuex';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        measurements        : null,
        heightFeet          : '',
        heightInches        : '',
        weight              : '',

        coatSize            : '',
        coatLength          : '',
        coatSleeve          : '',
        pantSize            : '',
        pantLength          : '',
        pantType            : 'R',
        shirtNeckSize       : '',
        shirtSleeveLength   : '',
        shoeSize            : '',
        shoeType            : 'W',
        lastMeasurements    : {},

        feet                : ['3','4','5', '6','7'],
        inches              : ['0','1','2','3','4','5','6','7','8','9','10','11'],
        coatLengthOptions   : [
            { id: 'B', name: 'Boy' },
            { id: 'S', name: 'Short' },
            { id: 'R', name: 'Regular' },
            { id: 'L', name: 'Long' }
        ],
        pantTypeOptions     : [ 
            { id: 'B', name: 'Boy' }, 
            { id: 'R', name: 'Regular' }
        ],
        shoeTypeOptions     : [ 
            { id: 'B', name: 'Boy' }, 
            { id: 'W', name: 'Regular' }
        ],

        rules               : {
            required        : v => !!v || 'required',
            intOnly         : v => _st.isInteger( v ) || 'must be valid',
            numeric         : v => _st.isNumeric( v ) || 'must be numeric',
            inches          : v => v >= 0 && v < 12 || 'must be valid'
        },

        calculating         : false,

        videoDialog         : false,
        videoSrc            : '',
    }),
    computed: {
        ...mapGetters({
            customer    : 'customer',
            event       : 'event'
        }),
    },
    mounted() {
        if( _st.isNUE( this.customer.id ) ) {
            this.$router.push('/');
            return;
        }

        this.loadMeasurements();
    },
    methods: {
        ...mapActions({
            setMeasurements : 'setMeasurements',
        }),
        async loadMeasurements() {
            if( _st.isNUE( this.customer.id ) )
                return;

            try {
                let api = new API();
                let res = await api.get(`/customer-measurements/${this.customer.id}`);

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error ? 'Error' : 'Warning', res.data.message, res.data.status ? 'error' : 'warning');
                    return;
                }

                this.measurements = res.data.data;

                let shirtSize = this.measurements.shirtSize;
                let neckSize = '';

                if( shirtSize.includes( 'XS' ) ) neckSize = 13;
                else if( shirtSize.includes( 'S' ) ) neckSize = 14;
                else if( shirtSize.includes( 'M' ) ) neckSize = 15;
                else if( shirtSize.includes( 'XL' ) ) neckSize = 17;
                else if( shirtSize.includes( 'L' ) ) neckSize = 16;
                else if( shirtSize.includes( '2X' ) ) neckSize = 18;
                else if( shirtSize.includes( '3X' ) ) neckSize = 19;
                else if( shirtSize.includes( '4X' ) ) neckSize = 20;
                else if( shirtSize.includes( '5X' ) ) neckSize = 22;

                this.coatSize = this.measurements.coatSize;
                this.coatLength = this.measurements.coatLength;
                this.coatSleeve = this.measurements.coatSleeve;
                this.pantSize = this.measurements.pantWaist;
                this.pantType = this.measurements.pantType;
                this.pantLength = this.measurements.pantOutseam;
                this.shirtNeckSize = neckSize;
                this.shirtSleeveLength = parseInt( this.measurements.coatSleeve ) + 10;
                this.shoeSize = this.measurements.shoeSize;
                this.shoeType = this.measurements.shoeType;
            }
            catch(error) {
                this.mxShowMessage('Error', error, 'error');
            }
        },
        async calculateMeasurements() {
            if( !this.$refs.formHeightWeight.validate() )
                return;

            try {
                let api = new API();

                this.calculating = true;
                let res = await api.post('/calculate-measurements', {
                    heightFeet      : this.heightFeet, 
                    heightInches    : this.heightInches,
                    weight          : this.weight,
                    fit             : 'slim'
                });
                this.calculating = false;
                
                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error === true ? 'Error' : 'Warning', res.data.message, res.data.error === true ? 'error' : 'warning');
                    return;
                }

                this.measurements = {
                    coatSize        : res.data.data.jacketSize, 
                    coatLength      : res.data.data.jacketLength, 
                    coatSleeve      : res.data.data.shirtSleeveLength - 10, 
                    shirtSize       : '', 
                    pantWaist       : res.data.data.pantSize, 
                    pantType        : '', 
                    pantOutseam     : res.data.data.pantLength, 
                    shoeSize        : '', 
                    shoeType        : ''
                };

                if( res.data.data === false )
                    return;

                this.coatSize = res.data.data.jacketSize;
                this.coatLength = res.data.data.jacketLength;
                this.coatSleeve = res.data.data.shirtSleeveSize - 10;
                this.pantSize = res.data.data.pantSize;
                this.pantLength = parseInt( res.data.data.pantLength ) + 10;
                this.shirtNeckSize = res.data.data.shirtNeckSize;
                this.shirtSleeveLength = res.data.data.shirtSleeveSize;
            }
            catch(error) {
                this.calculating = false;
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        async nextStep() {
            if( !this.$refs.form.validate() )
                return;

            try {
                // create or update the customer measurements
                let api = new API();

                this.loading = true;
                let res = await api.post('/customer-measurements', {
                    customerId          : this.customer.id,
                    coatSize            : this.coatSize,
                    coatLength          : this.coatLength,
                    coatSleeve          : this.coatSleeve,
                    pantSize            : this.pantSize,
                    pantLength          : this.pantLength,
                    shirtNeckSize       : this.shirtNeckSize,
                    shirtSleeveLength   : this.shirtSleeveLength,
                    shoeSize            : this.shoeSize,
                });

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                    return;
                }

                this.setMeasurements( res.data.data ); // cambiar a set measurement
                this.$router.push('/checkout');
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        getCoatLengthSubtitle( lengthId ) {
            switch( lengthId ) {
                case 'B': return '';
                case 'S': return "Estimated height 5' 7'' or shorter";
                case 'R': return "Estimated height 5' 8'' - 6' 1''";
                case 'L': return "Estimaed height 6' 2'' or higher";
            }
        },
        openVideo( src ) {
            this.videoSrc = src;
            this.videoDialog = true;
        },
        closeVideo() {
            this.videoSrc = '';
            this.videoDialog = false;
        }
    },
    directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}
</style>